
import CloudFun, { Condition, defineComponent, Operator, ref } from '@cloudfun/core'
import Grid, { GridOptions } from '@/cloudfun/components/Grid.vue'

export default defineComponent({
  components: {
    Grid
  },
  setup () {
    const model = CloudFun.current?.model

    const grid = ref<any>({})

    var printColumns = [
      { field: 'Code' },
      { field: 'Name' },
      { field: 'Width' },
      { field: 'Height' },
      { field: 'Remark' }
    ]

    const gridOptions: GridOptions = {
      title: '屬性',
      toolbarConfig: {
        custom: true,
        refresh: true
      },
      printConfig: { sheetName: '廣告清單', columns: printColumns, modes: ['current', 'selected', 'all'] },
      exportConfig: { filename: '廣告清單', type: 'csv', types: ['html', 'csv'], mode: 'all', modes: ['current', 'selected', 'all'], columns: printColumns },
      columns: [
        { field: 'Code', title: '代碼', showHeaderOverflow: true, showOverflow: true, sortable: true },
        { field: 'Name', title: '名稱', showHeaderOverflow: true, showOverflow: true, sortable: true },
        { field: "Selectable", title: "選擇型", showHeaderOverflow: true, showOverflow: true, sortable: true, resizable: false, width: 100, cellRender: { name: "$switch", props: { openLabel: '是', closeLabel: '否', disabled: true } } },
        { field: "Required", title: "必填", showHeaderOverflow: true, showOverflow: true, sortable: true, resizable: false, width: 100, cellRender: { name: "$switch", props: { openLabel: '是', closeLabel: '否', disabled: true } } },
        { field: 'Remark', title: '備註', resizable: false },
        { field: "Ordinal", title: "排序", showHeaderOverflow: true, showOverflow: true, sortable: true, resizable: false, width: 100 },
      ],
      promises: {
        query: model ? (params) => model.dispatch('attribute/query', params) : undefined,
        queryAll: model ? () => model.dispatch('attribute/query') : undefined,
        save: model ? (params) => model.dispatch('attribute/save', params) : undefined
      },
      modalConfig: { width: 800, showFooter: true }
    }
    const formOptions = {
      items: [
        { field: 'Code', title: '代碼', span: 8, itemRender: { name: '$input', props: { placeholder: '請輸入文字', clearable: true } } },
        { field: "Selectable", title: "選擇型", span: 8, itemRender: { name: "$select", options: [{ label: "是", value: true }, { label: "否", value: false }] } },
        { field: "Required", title: "必填", span: 8, itemRender: { name: "$select", options: [{ label: "是", value: true }, { label: "否", value: false }] } },
        { field: 'Name', title: '名稱', span: 16, itemRender: { name: '$input', props: { placeholder: '請輸入文字', clearable: true } } },
        { field: 'Ordinal', title: '排序', span: 8, itemRender: { name: '$input', props: { type: 'number', placeholder: '請輸入文字', clearable: true } } },
        { field: 'Remark', title: '備註', span: 24, itemRender: { name: '$input', props: { placeholder: '請輸入文字', clearable: true } } },
      ],
      rules: {
        Code: [{ type: 'string', max: 32, required: true }],
        Name: [{ type: 'string', max: 64, required: true }],
        Remark: [{ type: 'string', max: 512 }]
      }
    }

    const valueGrid = ref({})

    const valueGridOptions : GridOptions = {
      mode: 'inline',
      multiselect: false,
      columns: [
        { field: 'TextValue', title: '文字(顯示或查詢用)', showOverflow: true, sortable: true, resizable: false, editRender: { name: '$input', immediate: true } },
        { field: 'DecimalValue', title: '數值(比較或排序用)', showOverflow: true, sortable: true, resizable: false, editRender: { name: '$input', immediate: true, props: { type: 'number' } } },
        { field: 'Value', title: '值', showOverflow: true, sortable: true, resizable: false, editRender: { name: '$input', immediate: true } },
      ],
      promises: {
        query: model ? (params) => { params.condition = new Condition("AttributeId", Operator.Equal, grid.value.editingRow?.Id || "").and(params.condition!); return model.dispatch("attributeValue/query", params); } : undefined, // eslint-disable-line
        queryAll: model ? () => model.dispatch('attributeValue/query') : undefined,
        save: model ? (params) => model.dispatch('attributeValue/save', params) : undefined
      },
      modalConfig: { width: 600, showFooter: true }
    }

    return {
      grid,
      gridOptions,
      formOptions,
      valueGrid,
      valueGridOptions,
      baseUrl: process.env.VUE_APP_BACKEND_URL
    }
  },
  methods: {
    onGridRemoveSelectedRows (rows: any[], callback: any) {
      cash('#batch-dropdown').dropdown('hide')
      callback()
    },
  }
})
